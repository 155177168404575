import { useQuery } from '@apollo/client'
import { MenuItem, TextField, Typography } from '@mui/material'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../../components/data-grid/context'
import { GetPurchasePriceListsDocument } from '../../../../generated/graphql'
import { useStateParams } from '../../../../hooks/state-params'
import { useTracking } from '../../../../hooks/tracking'
import { ArrayElement } from '../../../../utils/types'

type QueryParamState = {
  priceList?: string
}

export const PurchasePriceListFilter = () => {
  const { t } = useTranslation(['purchase_prices'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()
  const { data: priceListsData, loading: isLoading } = useQuery(
    GetPurchasePriceListsDocument,
    { variables: { num: 1000 } },
  )

  const priceLists = useMemo(
    () => priceListsData?.purchasePriceLists.items || [],
    [priceListsData?.purchasePriceLists],
  )

  const firstPricelistId = priceLists?.[0]?.id
  const filterKey = 'priceList'

  const [queryParams, setQueryParams] = useStateParams<QueryParamState>()

  const priceListId = queryParams[filterKey] || firstPricelistId

  const getPriceListLabel = useCallback(
    (pricelist: ArrayElement<typeof priceLists>) =>
      `${pricelist.name}${
        pricelist.currency ? ` (${pricelist.currency})` : ''
      }`,
    [],
  )

  useEffect(() => {
    registerFilter({
      key: filterKey,
      isReady: !isLoading,
    })
  }, [isLoading, registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [
    firstPricelistId,
    getPriceListLabel,
    priceLists,
    setFilter,
    setQueryParams,
    subscribeOnResetFilter,
  ])

  useEffect(() => {
    if (priceListId === undefined) {
      removeFilter(filterKey)
    } else {
      const priceList = priceLists?.find((x) => x.id === priceListId)

      setFilter(filterKey, {
        label: t('purchase_prices:purchase_price_list_filter_tag_label', {
          priceList: priceList
            ? getPriceListLabel(priceList)
            : String(priceListId),
        }),
        isDefault: priceLists?.[0]?.id === priceListId,
        value: priceList,
      })
    }
  }, [
    t,
    firstPricelistId,
    getPriceListLabel,
    priceListId,
    priceLists,
    removeFilter,
    setFilter,
  ])

  return (
    <TextField
      onChange={(event) => setQueryParams({ [filterKey]: event.target.value })}
      value={priceLists?.length ? priceListId : ''}
      data-testid="purchase-price-list-filter"
      select
      label={t('purchase_prices:purchase_price_list')}
    >
      <MenuItem sx={{ display: 'none' }} />
      {priceLists?.map((x) => (
        <MenuItem
          key={x.id}
          value={x.id}
          divider
          data-testid={`purchase-price-list-filter-price-list-${x.id}`}
          onClick={trackButtonClickEvent({
            name: 'purchase-price-list-filter-select-price-list',

            id: x.id,
            pricelistName: x.name,
          })}
        >
          <Typography variant="body02">{getPriceListLabel(x)}</Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}
