import { ExtendableMetadata, TrackAction } from './types'

type DialogMetadata = ExtendableMetadata & {
  // dialogName: string
}

type TrackDialog = TrackAction<{ (metadata: DialogMetadata): void }>

export const trackDialogOpen: TrackDialog = (track) => (metadata) => {
  void track(`DialogOpen`, {
    ...metadata,

    category: 'Dialog',
    label: metadata.name,
  })
}

export const trackDialogClose: TrackDialog = (track) => (metadata) => {
  void track(`DialogClose`, {
    ...metadata,

    category: 'Dialog',
    label: metadata.name,
  })
}
