import { useEffect, useRef } from 'react'
import { DashboardConfig, DashboardDataQuery } from '../../generated/graphql'

type Props = {
  templateUrl: string | undefined
  dashboard: DashboardDataQuery['dashboardData']['dashboard'] | undefined
  config: DashboardConfig | undefined
}

const removeTrailingSlash = (url?: string) => {
  if (!url) return
  return url.replace(/\/+$/, '')
}

export const useDashboardMessage = (props: Props) => {
  const { templateUrl, dashboard, config } = props
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (!config || !templateUrl || !dashboard) return

    const isValidMessageData = (event: MessageEvent<unknown>) => {
      const eventData = event.data

      return (
        eventData &&
        typeof eventData === 'object' &&
        'message' in eventData &&
        eventData.message === 'ready' &&
        removeTrailingSlash(event.origin) === removeTrailingSlash(templateUrl)
      )
    }

    const receiveMessage = (event: MessageEvent) => {
      if (!isValidMessageData(event)) return

      if (!dashboard || !templateUrl) return

      iframeRef.current?.contentWindow?.postMessage(
        {
          type: 'dashboard-data',
          config,
          dashboard,
        },
        templateUrl,
      )
    }

    window.addEventListener('message', receiveMessage, false)
    return () => window.removeEventListener('message', receiveMessage)
  }, [dashboard, templateUrl, config])

  return { iframeRef }
}
