import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DashboardsDocument } from '../../generated/graphql'
import { useFormFilter } from '../../hooks/form-filter'
import { useSelectFirst } from '../../hooks/use-select-first'
import { SelectInput } from '../../inputs/select-input'

export type DashboardIdFilter = {
  dashboardId?: string
}

export const DashboardIdFilter = () => {
  const { t } = useTranslation(['shared', 'store_dashboards'])

  const { data, loading: isLoading } = useQuery(DashboardsDocument, {
    fetchPolicy: 'cache-and-network',
  })

  const options = useMemo(
    () =>
      data?.dashboards
        .map((dashboard) => ({
          value: dashboard.id,
          name: dashboard.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [data?.dashboards],
  )

  const { formContext } = useFormFilter<DashboardIdFilter>({
    formProps: {
      defaultValues: { dashboardId: '' },
    },
  })

  useSelectFirst({ formContext, isLoading, options, name: 'dashboardId' })

  return (
    <SelectInput
      name="dashboardId"
      control={formContext.control}
      options={options}
      disabled={isLoading}
      label={t('store_dashboards:dashboard_label')}
    />
  )
}
