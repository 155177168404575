import { Avatar, Box, Stack, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { PaymentUnknownIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import {
  AllPaymentTypesQuery,
  PosPaymentMethod,
} from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { paymentMethodIconMap } from './utils'

export const PaymentMethodRenderer = (
  params: GridRenderCellParams<
    ArrayElement<AllPaymentTypesQuery['allPaymentTypes']>,
    string
  >,
) => <PaymentMethod {...params.row} />

type PaymentMethodProps = {
  src?: string
  paymentmethodid: PosPaymentMethod
}

const PaymentMethod = (props: PaymentMethodProps) => {
  const { t } = useTranslation(['payment_options'])

  const PaymentIcon =
    paymentMethodIconMap[props.paymentmethodid] ?? PaymentUnknownIcon

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <Box sx={{ display: 'flex' }}>
        <Avatar
          variant="square"
          src={props.src}
          sx={{
            width: 30,
            height: 30,
            background: 'white',
          }}
        >
          <PaymentIcon fontSize="medium" />
        </Avatar>
      </Box>
      <Stack direction="column" spacing={0} ml={1}>
        <Typography>
          {t(`payment_options:payment_method.${props.paymentmethodid}`)}
        </Typography>
        {/* TODO: Implement payment type status */}
        {/* <Typography variant="caption">{'Status: Unknown'}</Typography> */}
      </Stack>
    </Box>
  )
}
