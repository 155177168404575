import { List, ListItem, ListItemText } from '@mui/material'
import { PaymentUnknownIcon, SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { PosPaymentMethod } from '../../../../generated/graphql'
import { paymentMethodIconMap } from '../utils'

type Props = {
  isLoading?: boolean
  paymentMethodId?: PosPaymentMethod
}

export const Method = (props: Props) => {
  const { t } = useTranslation(['payment_options'])
  const { isLoading, paymentMethodId } = props

  const PaymentIcon = paymentMethodId
    ? paymentMethodIconMap[paymentMethodId]
    : PaymentUnknownIcon

  return (
    <List>
      <SectionHeader>{t('payment_options:method')}</SectionHeader>

      {isLoading && (
        <ListItemSkeleton
          isLoading={isLoading}
          secondaryAction="-"
          childrenSkeleton
        />
      )}

      {!isLoading && paymentMethodId && (
        <ListItem>
          <PaymentIcon fontSize="large" />

          <ListItemText
            primary={t(`payment_options:payment_method.${paymentMethodId}`)}
            primaryTypographyProps={{
              sx: {
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              },
            }}
            secondaryTypographyProps={{
              whiteSpace: 'nowrap',
            }}
          />
        </ListItem>
      )}
    </List>
  )
}
