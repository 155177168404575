export const RootRoute = {
  Root: '/:siteId',

  Callback: '/callback',

  Login: '/login',
  Logout: '/logout',

  ResetPassword: '/reset-password',

  Home: '/:siteId/home',
  Orders: '/:siteId/orders',
  OrdersPrint: '/:siteId/orders/print/:id',

  OrderSearch: '/:siteId/order-search',

  Journals: '/:siteId/journals',

  StoreDashboards: '/:siteId/store-dashboards',

  CashRegisters: '/:siteId/cash-registers',
  CashRegistersPrint: '/:siteId/cash-registers/print/:id',

  Shipments: '/:siteId/shipments',
  Shipment: '/:siteId/shipments/:id',
  ShipmentNew: '/:siteId/shipments/new',

  ServiceOrders: '/:siteId/service-orders',
  ServiceOrder: '/:siteId/service-orders/:id',
  ServiceOrderNew: '/:siteId/service-orders/new',

  Stock: '/:siteId/stock',
  StockLevels: '/:siteId/stock-levels',

  DeliveriesIn: '/:siteId/delivery-in',
  DeliveryIn: '/:siteId/delivery-in/:warehouseId/:warehouseBatchId',

  DeliveriesOut: '/:siteId/delivery-out',
  DeliveryOut: '/:siteId/delivery-out/:warehouseId/:warehouseBatchId',

  Stocktakings: '/:siteId/stocktaking',
  Stocktaking: '/:siteId/stocktaking/:warehouseId/:warehouseBatchId',

  InStoreReplenishment: '/:siteId/in-store-replenishment',
  InStoreReplenishmentPrint: '/:siteId/in-store-replenishment/print/:id',

  Statistics: '/:siteId/statistics',

  Users: '/:siteId/users',
  User: '/:siteId/users/:id',
  UserNew: '/:siteId/users/new',

  Apps: '/:siteId/apps',

  Products: '/:siteId/products',
  Product: '/:siteId/products/:id',
  ProductNew: '/:siteId/products/new',

  Campaigns: '/:siteId/campaigns',
  Campaign: '/:siteId/campaigns/:id',
  CampaignNew: '/:siteId/campaigns/new',

  InfluencerCodes: '/:siteId/influencer-codes',
  InfluencerCode: '/:siteId/influencer-codes/:id',
  InfluencerCodeNew: '/:siteId/influencer-codes/new',

  Prices: '/:siteId/prices',
  PurchasePrices: '/:siteId/purchase-prices',

  Settings: '/:siteId/settings',
  SettingsGeneral: '/:siteId/settings/general',

  SettingsProfile: '/:siteId/settings/profile',

  SettingsAuthentication: '/:siteId/settings/authentication',

  SettingsAccessLogs: '/:siteId/settings/access-logs',

  SettingsStores: '/:siteId/settings/stores',
  SettingsStore: '/:siteId/settings/stores/:id',
  SettingsStoreNew: '/:siteId/settings/stores/new',

  SettingsWarehouses: '/:siteId/settings/warehouses',
  SettingsWarehouse: '/:siteId/settings/warehouses/:id',
  SettingsWarehouseNew: '/:siteId/settings/warehouses/new',

  SettingsProductsBase: '/:siteId/settings/products',
  SettingsProductsGroups: '/:siteId/settings/products/groups',
  SettingsProductsBrands: '/:siteId/settings/products/brands',

  SettingsCustomAttributes: '/:siteId/settings/products/custom-attributes',
  SettingsCustomAttribute: '/:siteId/settings/products/custom-attributes/:id',
  SettingsCustomAttributeNew:
    '/:siteId/settings/products/custom-attributes/new',

  SettingsProductsCategories: '/:siteId/settings/products/categories',

  SettingsPriceLists: '/:siteId/settings/price-lists',
  SettingsPriceList: '/:siteId/settings/price-lists/:id',
  SettingsPriceListNew: '/:siteId/settings/price-lists/new',

  SettingsPurchasePriceLists: '/:siteId/settings/purchase-price-lists',
  SettingsPurchasePriceList: '/:siteId/settings/purchase-price-lists/:id',
  SettingsPurchasePriceListNew: '/:siteId/settings/purchase-price-lists/new',

  SettingsCampaigns: '/:siteId/settings/campaigns',
  SettingsImportData: '/:siteId/settings/import',
  SettingsCustomAPI: '/:siteId/settings/custom-api',

  SettingsPaymentOptions: '/:siteId/settings/payment-options',
  SettingsPaymentOption: '/:siteId/settings/payment-options/:id',
  SettingsPaymentOptionNew: '/:siteId/settings/payment-options/new',

  SettingsPosGeneral: '/:siteId/settings/pos/general',
  SettingsPosDashboards: '/:siteId/settings/pos/dashboards',
  SettingsPosDashboard: '/:siteId/settings/pos/dashboards/:id',
  SettingsPosCart: '/:siteId/settings/pos/cart',
  SettingsPosRequiredFields: '/:siteId/settings/pos/required-fields',

  SettingsAdyenTerminalApi: '/:siteId/settings/apps/adyen-terminal-api',
  SettingsAdyenTapToPay: '/:siteId/settings/apps/adyen-tap-to-pay',
  SettingsCtuNorway: '/:siteId/settings/apps/ctu-norway',

  SettingsReasonCodes: '/:siteId/settings/reason-codes',
  SettingsReasonCode: '/:siteId/settings/reason-codes/:id',
  SettingsReasonCodeNew: '/:siteId/settings/reason-codes/new',

  SettingsSalesTaxes: '/:siteId/settings/sales-taxes',
  SettingsSalesTax: '/:siteId/settings/sales-taxes/:id',
  SettingsSalesTaxNew: '/:siteId/settings/sales-taxes/new',

  SettingsSalesTaxGroups: '/:siteId/settings/sales-tax-groups',
  SettingsSalesTaxGroup: '/:siteId/settings/sales-tax-groups/:id',
  SettingsSalesTaxGroupNew: '/:siteId/settings/sales-tax-groups/new',

  SettingsUserTags: '/:siteId/settings/user-tags',
}
