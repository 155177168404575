import { PageData } from 'analytics'
import { useCallback, useEffect, useMemo } from 'react'
import { useAnalytics } from 'use-analytics'
import { useMe } from '../me'
import { trackButtonClick, trackButtonClickEvent } from './button'
import { trackDialogClose, trackDialogOpen } from './dialog'
import { trackDragEnd, trackDragStart } from './drag'
import { trackFormError, trackFormSuccess } from './form'
import {
  trackInput,
  trackInputBlur,
  trackInputChange,
  trackInputFocus,
} from './input'
import { trackMenuClose, trackMenuOpen } from './menu'
import {
  CustomIdentify,
  CustomTrack,
  ExtendableMetadata,
  UserProperties,
} from './types'

export const useTracking = () => {
  const {
    track: originalTrack,
    page,
    identify: originalIdentify,
    reset,
    user,
  } = useAnalytics()
  const { me } = useMe()
  const track: CustomTrack = originalTrack
  const identify: CustomIdentify = originalIdentify

  const userId = useMemo(() => user('userId') as string | null, [user])

  const accountId = Number(user('traits.accountId'))
  const siteId = Number(user('traits.siteId'))
  const userRole = user('traits.userRole')

  useEffect(() => {
    if (!me?.user?.id) return

    const userUpdates: UserProperties = {}

    if (userId !== me.user.id) {
      userUpdates.userId = me.user?.id
    }

    if (accountId !== me.accountId) {
      userUpdates.accountId = me.accountId
    }

    if (siteId !== me.siteId) {
      userUpdates.siteId = me.siteId
    }

    if (userRole !== me.role) {
      userUpdates.userRole = me.role
    }

    if (Object.keys(userUpdates).length > 0) {
      void identify(me.user.id, { ...userUpdates })
    }
  }, [
    identify,
    me?.accountId,
    me?.user?.id,
    me?.role,
    me?.siteId,
    userId,
    accountId,
    siteId,
    userRole,
  ])

  /**
   * NOTE: There is no good reason to keep function like `trackButtonClick`
   * in a separate file. Feel free to refactor this.
   */

  const _trackButtonClick = useCallback(
    (metadata: ExtendableMetadata) => trackButtonClick(track)(metadata),
    [track],
  )

  const _trackButtonClickEvent = useCallback(
    (
      metadata: ExtendableMetadata,
      onClick?: React.MouseEventHandler | ((event?: unknown) => Promise<void>),
    ) => trackButtonClickEvent(track)(metadata, onClick),
    [track],
  )

  const _trackInput = useCallback(
    (metadata: ExtendableMetadata) => trackInput(track)(metadata),
    [track],
  )

  const _trackInputFocus = useCallback(
    (metadata: ExtendableMetadata) => trackInputFocus(track)(metadata),
    [track],
  )

  const _trackInputBlur = useCallback(
    (metadata: ExtendableMetadata) => trackInputBlur(track)(metadata),
    [track],
  )

  const _trackInputChange = useCallback(
    (metadata: ExtendableMetadata) => trackInputChange(track)(metadata),
    [track],
  )

  const _trackDialogOpen = useCallback(
    (metadata: ExtendableMetadata) => trackDialogOpen(track)(metadata),
    [track],
  )

  const _trackDialogClose = useCallback(
    (metadata: ExtendableMetadata) => trackDialogClose(track)(metadata),
    [track],
  )

  const _trackFormSuccess = useCallback(
    (metadata: ExtendableMetadata) => trackFormSuccess(track)(metadata),
    [track],
  )

  const _trackFormError = useCallback(
    (metadata: ExtendableMetadata) => trackFormError(track)(metadata),
    [track],
  )

  const _trackDragStart = useCallback(
    (metadata: ExtendableMetadata) => trackDragStart(track)(metadata),
    [track],
  )

  const _trackDragEnd = useCallback(
    (metadata: ExtendableMetadata) => trackDragEnd(track)(metadata),
    [track],
  )

  const _trackMenuOpen = useCallback(
    (metadata: ExtendableMetadata) => trackMenuOpen(track)(metadata),
    [track],
  )

  const _trackMenuClose = useCallback(
    (metadata: ExtendableMetadata) => trackMenuClose(track)(metadata),
    [track],
  )

  const _track = useCallback(
    (name: string, metadata: ExtendableMetadata & { category: string }) => {
      void track(name, {
        ...metadata,

        label: metadata.name,
      })
    },
    [track],
  )

  const _page = useCallback(
    (pageData: PageData) => page({ ...pageData }),
    [page],
  )

  return {
    identify,
    reset,
    page: _page,
    track: _track,

    trackButtonClick: _trackButtonClick,
    trackButtonClickEvent: _trackButtonClickEvent,

    trackInput: _trackInput,
    trackInputFocus: _trackInputFocus,
    trackInputBlur: _trackInputBlur,
    trackInputChange: _trackInputChange,

    trackDialogOpen: _trackDialogOpen,
    trackDialogClose: _trackDialogClose,

    trackFormSuccess: _trackFormSuccess,
    trackFormError: _trackFormError,

    trackDragStart: _trackDragStart,
    trackDragEnd: _trackDragEnd,

    trackMenuOpen: _trackMenuOpen,
    trackMenuClose: _trackMenuClose,
  }
}
