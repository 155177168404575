import { Box, List, useMediaQuery, useTheme } from '@mui/material'
import { ChevronLeftIcon, ChevronRightIcon } from '@sitoo/mui-components'
import { Fragment, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { sidebarOpenState } from '../../cache'
import { MenuItem, SidebarListItem } from '../sidebar-list-item'
import { SiteSelector } from '../site-selector'
import { ArrowButton, Category, SidebarDrawer } from './styled'

export type MenuSection = {
  name: string
  items: MenuItem[]
}

type Props = {
  footer?: React.ReactNode
  header?: React.ReactNode
  isSettings?: boolean
  menuSections: MenuSection[]
}

export const SidebarList = (props: Props) => {
  const theme = useTheme()
  const { menuSections, footer, header, isSettings } = props
  const { pathname } = useLocation()
  const prevPathname = usePrevious(pathname)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const closeSidebar = () => {
    sidebarOpenState(false)
  }

  const toggleSidebar = () => {
    sidebarOpenState(!sidebarOpenState())
  }

  useEffect(() => {
    if (sidebarOpenState() === undefined) {
      sidebarOpenState(!isMobile)
    }

    if (isMobile && prevPathname !== pathname) {
      closeSidebar()
    }
  }, [pathname, prevPathname, isMobile])

  const activeMenuSections = useMemo(() => {
    for (const menuSection of menuSections) {
      for (const menuItem of menuSection.items) {
        const activeItems = menuItem?.items?.filter((x) =>
          typeof x.active === 'boolean' ? x.active : true,
        )

        // If there is only active item, display it instead of its parent
        if (activeItems?.length === 1 && activeItems[0]) {
          menuItem.items = undefined
          menuItem.to = activeItems[0].to
          menuItem.name = activeItems[0].name
          menuItem.counter = activeItems[0].counter
        } else {
          menuItem.items = activeItems
        }
      }
    }

    return menuSections.filter(({ items }) => items.length > 0)
  }, [menuSections])

  const getActiveItems = (items: MenuItem[]): MenuItem[] => {
    return items.filter(({ active, items: childItems }) => {
      if (childItems) {
        return childItems.some(({ active }) => active)
      }

      return typeof active === 'boolean' ? active : true
    })
  }

  return (
    <SidebarDrawer
      isSettings={isSettings}
      variant="permanent"
      open={sidebarOpenState()}
      slotProps={{ backdrop: { onClick: closeSidebar } }}
      sx={{ background: (theme) => theme.palette.gray80 }}
    >
      <Box
        sx={{
          position: 'fixed',
          width: 'inherit',
          pointerEvents: 'none',
          height: '100vh',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <ArrowButton
          className="arrow-button"
          onClick={toggleSidebar}
          open={sidebarOpenState()}
        >
          {sidebarOpenState() ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </ArrowButton>
      </Box>
      <Box
        sx={{
          mt: 7,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {header}

        <Box sx={{ [theme.breakpoints.up('sm')]: { display: 'none' } }}>
          <SiteSelector isSidebar />
        </Box>

        <List sx={{ flexGrow: 1, [theme.breakpoints.up('sm')]: { mt: 2 } }}>
          {activeMenuSections.map((category, menuIndex) => {
            const activeItems = getActiveItems(category.items)

            if (!activeItems.length) {
              return null
            }

            return (
              <Fragment key={`${category.name}-${menuIndex}`}>
                <Category variant="subheader">{category.name}</Category>

                {activeItems.map((item) => (
                  <SidebarListItem key={item.name} {...item} />
                ))}
              </Fragment>
            )
          })}
        </List>

        {footer}
      </Box>
    </SidebarDrawer>
  )
}
