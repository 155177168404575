import {
  Box,
  Button,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { SearchProductField } from '../../../components/search-product-field'
import {
  ProductFieldsFragment,
  ProductGroupType,
  ServiceOrderItem,
  ServiceOrderQuery,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { ServiceOrderServiceFormContext } from '../shared'
import { useMe } from '../../../hooks/me'

type Props = {
  open: boolean
  onClose(): void
  dataTestid?: string
  onAdd(service: ServiceOrderItem): void
  onSave(service: ServiceOrderItem): void
  onDelete(): void
  service?: ArrayElement<
    ServiceOrderQuery['serviceOrder']['product']['services']
  > | null
}

export const SetServiceDialog = (props: Props) => {
  const { t } = useTranslation(['service-orders', 'shared'])
  const dialogName = 'set-service-order-service'
  const { id: serviceOrderId } = useParams()
  const { me } = useMe()

  const { trackButtonClickEvent, trackDialogClose, trackDialogOpen } =
    useTracking()

  const defaultValues: ServiceOrderServiceFormContext = useMemo(
    () => ({
      sku: '',
      name: '',
      quantity: 1,
      unit_price: null,
      cost_share: 100,
      note: '',
    }),
    [],
  )

  const { register, formState, setValue, reset, watch } =
    useForm<ServiceOrderServiceFormContext>({
      defaultValues,
    })

  useEffect(() => {
    if (props.service) {
      reset(props.service, { keepDefaultValues: false })
      trackDialogOpen({ name: dialogName })
    } else {
      reset(defaultValues)
      trackDialogClose({ name: dialogName })
    }
  }, [
    props.open,
    props.service,
    trackDialogOpen,
    trackDialogClose,
    reset,
    defaultValues,
  ])

  const values = watch()

  const handleSubmit = () => {
    trackButtonClickEvent(
      serviceOrderId
        ? { name: `${dialogName}-dialog-save` }
        : { name: `${dialogName}-dialog-add` },
      props.onClose,
    )
    if (props.service) {
      props.onSave({ ...values, unit_price: values.unit_price || null })
    } else {
      props.onAdd({ ...values, unit_price: values.unit_price || null })
    }

    props.onClose()
  }

  const handleOnProductSelect = (product: ProductFieldsFragment) => {
    if (product.title) setValue('name', product.title)
    if (product.sku) setValue('sku', product.sku)
    if (product.moneyprice) setValue('unit_price', product.moneyprice)
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      data-testid={props.dataTestid}
      maxWidth="xs"
      fullWidth
      sx={{ p: 4 }}
    >
      <DialogTitle
        type="extended"
        sx={{ pb: 2, justifyContent: 'space-between' }}
      >
        {props.service
          ? t('service_orders:service_order_form.edit_service')
          : t('service_orders:service_order_form.add_service')}
      </DialogTitle>

      <DialogContent>
        <>
          <SearchProductField
            sx={{ mb: 2 }}
            onChange={handleOnProductSelect}
            data-testid="search-service-product-input"
            fetchOpts={{
              num: 100,
              productGroupType: ProductGroupType.NUMBER_20,
            }}
          />
        </>
        <TextField
          error={!!formState?.errors.name}
          fullWidth
          helperText={formState?.errors.name?.message}
          label={t('service_orders:service_order_form.name')}
          data-testid="service-order-service-name-field"
          {...register('name')}
          sx={{ mb: 2 }}
        />
        <TextField
          error={!!formState?.errors.sku}
          fullWidth
          helperText={formState?.errors.sku?.message}
          label={t('service_orders:sku')}
          data-testid="service-order-service-sku-field"
          {...register('sku')}
          sx={{ mb: 2 }}
        />
        <TextField
          error={!!formState?.errors.quantity}
          fullWidth
          type="number"
          helperText={formState?.errors.quantity?.message}
          label={t('service_orders:service_order_form.quantity')}
          data-testid="service-order-service-quantity-field"
          {...register('quantity', { valueAsNumber: true })}
          sx={{ mb: 2 }}
        />
        <TextField
          error={!!formState?.errors.unit_price}
          fullWidth
          helperText={formState?.errors.unit_price?.message}
          label={`${t('service_orders:service_order_form.unit_price')} (${
            me?.currentSite.currencycode
          })`}
          data-testid="service-order-service-unit-price-field"
          {...register('unit_price')}
          sx={{ mb: 2 }}
        />
        <TextField
          error={!!formState?.errors.cost_share}
          fullWidth
          type="number"
          helperText={formState?.errors.cost_share?.message}
          label={t('service_orders:service_order_form.cost_share')}
          data-testid="service-order-service-cost-share-field"
          InputProps={{ inputProps: { max: 100, min: 0 } }}
          {...register('cost_share', { valueAsNumber: true })}
          sx={{ mb: 2 }}
        />
        <TextField
          error={!!formState?.errors.note}
          fullWidth
          multiline
          rows={2}
          helperText={formState?.errors.note?.message}
          label={t('service_orders:note')}
          data-testid="service-order-service-note-field"
          {...register('note')}
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Stack sx={{ width: '100%' }}>
          {props.service && (
            <Button
              onClick={() => {
                props.onDelete()
                props.onClose()
              }}
              color="error"
              fullWidth
              type="button"
              sx={{ mb: 2 }}
              data-testid="service-order-service-delete-button"
            >
              {t('service_orders:service_order_form.delete')}
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              onClick={trackButtonClickEvent(
                { name: `${dialogName}-dialog-cancel` },
                props.onClose,
              )}
              color="secondary"
              size="small"
              type="button"
              data-testid="service-order-service-cancel-button"
            >
              {t('shared:action.cancel')}
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              size="small"
              type="button"
              sx={{ ml: 2 }}
              data-testid="service-order-service-submit-button"
            >
              {t('shared:action.ok')}
            </Button>
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
