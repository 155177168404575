import { ExtendableMetadata, TrackAction } from './types'

type MenuMetadata = ExtendableMetadata & {
  // menuName: string
}

type TrackMenu = TrackAction<{ (metadata: MenuMetadata): void }>

export const trackMenuOpen: TrackMenu = (track) => (metadata) => {
  void track(`MenuOpen`, {
    ...metadata,

    category: 'Menu',
    label: metadata.name,
  })
}

export const trackMenuClose: TrackMenu = (track) => (metadata) => {
  void track(`MenuClose`, {
    ...metadata,

    category: 'Menu',
    label: metadata.name,
  })
}
