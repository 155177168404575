import { useQuery } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import {
  GetConfigVarsDocument,
  GetConfigVarsQuery,
  MeQuery,
  PermissionRole,
} from '../../generated/graphql'
import { useMe } from '../me'

export type Authorization = {
  loading: boolean
  isLoggedIn: boolean
  modules: ReturnType<typeof useAuthorization>['modules']
}

export const Roles = PermissionRole

type ConfigVarKey = keyof Omit<GetConfigVarsQuery['configVars'], '__typename'>

type SiteSettingKey = keyof Omit<MeQuery['me']['currentSite'], '__typename'>

export function useAuthorization() {
  const { me, loading: meLoading } = useMe()
  const { data: configVarsData, loading: varsLoading } = useQuery(
    GetConfigVarsDocument,
    { skip: !me?.user },
  )
  const configVars = configVarsData?.configVars

  const hasRole = useCallback(
    (roles: string[]) => {
      if (roles && me?.role) {
        return roles.includes(me.role)
      }

      return false
    },
    [me?.role],
  )

  const checkPermissions = useCallback(
    (moduleName: ConfigVarKey, roles?: string[]) => {
      const isEnabled = Boolean(configVars?.[moduleName])

      if (roles && me?.role) {
        return isEnabled && hasRole(roles)
      }

      return isEnabled
    },
    [configVars, hasRole, me?.role],
  )

  const checkSiteSetting = useCallback(
    (settingName: SiteSettingKey, roles?: string[]) => {
      const isEnabled = Boolean(me?.currentSite[settingName])

      if (roles && me?.role) {
        return isEnabled && hasRole(roles)
      }

      return isEnabled
    },
    [hasRole, me?.currentSite, me?.role],
  )

  const modules = useMemo(
    () => ({
      homeWidgets: hasRole([
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),

      orders: checkPermissions('orders', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),

      writeOrders: checkPermissions('orders', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),

      orderLookup: checkPermissions('orderLookup', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),

      journals: checkPermissions('journals', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.StoreManager,
        Roles.Staff,
      ]),

      storeDashboards: checkPermissions('storeDashboard', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),

      shipmentsV2: checkPermissions('shipments', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.StoreManager,
      ]),
      writeShipmentsV2: checkPermissions('shipments', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.StoreManager,
      ]),

      shipments: checkPermissions('shipmentsv3', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.StoreManager,
      ]),
      writeShipments: checkPermissions('shipmentsv3', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.StoreManager,
      ]),

      serviceOrders: checkPermissions('serviceOrders'),

      cashRegisters: checkPermissions('cashRegisters', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),

      activateCashRegisters:
        checkPermissions('cashRegisters', [Roles.SaLocal, Roles.Admin]) ||
        me?.user?.staff_pos_activation,

      products: checkPermissions('products', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),

      writeProducts: checkPermissions('products', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),

      prices: checkPermissions('prices', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),
      writePrices: checkPermissions('prices', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),

      purchasePrices: checkPermissions('purchasePrices', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),
      writePurchasePrices: checkPermissions('purchasePrices', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),

      campaigns:
        checkPermissions('campaigns', [
          Roles.SaLocal,
          Roles.Admin,
          Roles.Staff,
        ]) ||
        checkSiteSetting('allow_store_manager_campaigns', [Roles.StoreManager]),

      influencerCodes: checkPermissions('influencerCodes'),

      statistics: checkPermissions('statistics'),

      users: checkPermissions(
        'users',
        checkPermissions('limitUserManagement')
          ? [Roles.SaLocal, Roles.Admin, Roles.Staff]
          : [Roles.SaLocal, Roles.Admin, Roles.Staff, Roles.StoreManager],
      ),
      apps: checkPermissions('apps'),

      stock: checkPermissions('stock', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),
      writeStock: checkPermissions('stock', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),

      deliveryIn: checkPermissions('deliveryIn', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),
      writeDeliveryIn: checkPermissions('deliveryIn', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),

      deliveryOut: checkPermissions('deliveryOut', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),
      writeDeliveryOut: checkPermissions('deliveryOut', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),

      stocktaking: checkPermissions('stocktaking', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),
      writeStocktaking: checkPermissions('stocktaking', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),

      settingsGeneral: true,
      settingsPos: checkPermissions('posSettings', [
        Roles.SaLocal,
        Roles.Admin,
      ]),
      settingsPosGeneral: checkPermissions('posSettings', [
        Roles.SaLocal,
        Roles.Admin,
      ]),
      settingsPosCart: checkPermissions('posSettings', [
        Roles.SaLocal,
        Roles.Admin,
      ]),
      settingsPosDashboards: checkPermissions('posSettings', [
        Roles.SaLocal,
        Roles.Admin,
      ]),
      settingsPosRequiredFields: checkPermissions('posSettings', [
        Roles.SaLocal,
        Roles.Admin,
      ]),

      settingsPaymentOptions: checkPermissions('paymentSettings', [
        Roles.SaLocal,
        Roles.Admin,
      ]),

      settingsAdyenTerminalApi: checkPermissions('adyenTerminalApi', [
        Roles.SaLocal,
        Roles.Admin,
      ]),
      settingsAdyenTapToPay: checkPermissions('adyenTapToPay', [
        Roles.SaLocal,
        Roles.Admin,
      ]),
      settingsCtuNorway: checkPermissions('ctuNorway', [
        Roles.SaLocal,
        Roles.Admin,
      ]),

      settingsBrands: checkPermissions('products', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),
      writeSettingsBrands: checkPermissions('products', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),

      settingsProductGroups: checkPermissions('products', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),

      writeSettingsProductGroups: checkPermissions('products', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),

      settingsCategoriesNavigation: checkPermissions('products', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),

      writeSettingsCategoriesNavigation: checkPermissions('products', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),

      settingsCustomAttributes: checkPermissions('products', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),
      writeSettingsCustomAttributes: checkPermissions('products', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
      ]),
      settingsSalesTaxes: checkPermissions('salesTax', [
        Roles.SaLocal,
        Roles.Admin,
      ]),

      settingsStores: hasRole([Roles.SaLocal, Roles.Admin]),

      settingsReasonCodes: hasRole([Roles.SaLocal, Roles.Admin]),

      settingsWarehouses: hasRole([Roles.SaLocal, Roles.Admin]),

      inStoreReplenishment: checkPermissions('inStoreReplenishment', [
        Roles.SaLocal,
        Roles.Admin,
        Roles.Staff,
        Roles.StoreManager,
      ]),

      settingsPriceList: checkPermissions('prices', [
        Roles.SaLocal,
        Roles.Admin,
      ]),
      writeSettingsPriceList: checkPermissions('prices', [
        Roles.SaLocal,
        Roles.Admin,
      ]),

      settingsPurchasePriceList: checkPermissions('purchasePrices', [
        Roles.SaLocal,
        Roles.Admin,
      ]),
      writeSettingsPurchasePriceList: checkPermissions('purchasePrices', [
        Roles.SaLocal,
        Roles.Admin,
      ]),

      settingsRewards: checkPermissions('rewards', [
        Roles.SaLocal,
        Roles.Admin,
      ]),

      settingsImportPrices: checkPermissions('prices', [
        Roles.SaLocal,
        Roles.Admin,
      ]),

      settingsImportPurchasePrices: checkPermissions('purchasePrices', [
        Roles.SaLocal,
        Roles.Admin,
      ]),

      settingsImportStores: hasRole([Roles.SaLocal, Roles.Admin]),

      settingsImportWarehouses: hasRole([Roles.SaLocal, Roles.Admin]),

      settingsImportUsers: checkPermissions(
        'users',
        checkPermissions('limitUserManagement')
          ? [Roles.SaLocal, Roles.Admin]
          : [Roles.SaLocal, Roles.Admin, Roles.Staff, Roles.StoreManager],
      ),

      settingsImportInfluencerCodes: checkPermissions('influencerCodes', [
        Roles.SaLocal,
        Roles.Admin,
      ]),

      settingsAuthentication: hasRole([Roles.SaLocal, Roles.Admin]),

      settingsAccessLogs: hasRole([Roles.SaLocal, Roles.Admin]),

      directFeedback: checkPermissions('directFeedback'),
    }),
    [
      checkPermissions,
      checkSiteSetting,
      hasRole,
      me?.user?.staff_pos_activation,
    ],
  )

  return {
    loading: meLoading || varsLoading,
    isLoggedIn: !!me?.user?.email,
    modules,
  }
}
