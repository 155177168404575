import { FocusEventHandler, FormEventHandler } from 'react'
import { BasePayload, CustomTrack, ExtendableMetadata } from './types'

type InputMetadata = ExtendableMetadata & {
  // inputName: string
}

type InputChangeMetadata = InputMetadata & {
  trackValue?: boolean
}

export const trackInputFocus =
  (track: CustomTrack) =>
  (
    metadata: InputMetadata,
    onFocus?: FocusEventHandler | ((event?: unknown) => Promise<void>),
  ): FocusEventHandler =>
  (event) => {
    void track(`InputFocus`, {
      ...metadata,

      category: 'Input',
      label: metadata.name,
    })

    if (onFocus) void onFocus(event)
  }

export const trackInputBlur =
  (track: CustomTrack) =>
  (
    metadata: InputMetadata,
    onBlur?: FocusEventHandler | ((event?: unknown) => Promise<void>),
  ): FocusEventHandler =>
  (event) => {
    void track(`InputBlur`, {
      ...metadata,

      category: 'Input',
      label: metadata.name,
    })

    if (onBlur) void onBlur(event)
  }

export const trackInputChange =
  (track: CustomTrack) =>
  (
    metadata: InputChangeMetadata,
    onChange?: FormEventHandler | ((event?: unknown) => Promise<void>),
  ): FormEventHandler =>
  (event) => {
    const payload: BasePayload = {
      ...metadata,

      category: 'Input',
      label: metadata.name,
    }

    if (metadata.trackValue) {
      payload['value'] = (event.target as HTMLInputElement).value
    }

    void track(`InputChange`, payload)

    if (onChange) void onChange(event)
  }

export const trackInput =
  (track: CustomTrack) =>
  (
    metadata: InputChangeMetadata,
    events?: {
      onBlur?: FocusEventHandler
      onFocus?: FocusEventHandler
      onChange?: FormEventHandler
    },
  ) => {
    return {
      onBlur: trackInputBlur(track)(metadata, events?.onBlur),
      onFocus: trackInputFocus(track)(metadata, events?.onFocus),
      onChange: trackInputChange(track)(metadata, events?.onChange),
    }
  }
