import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'
import { useMemo } from 'react'
import { SelectInput } from '../../../../inputs/select-input'

export const PriorityField = () => {
  const { control } = useFormContext<CampaignFormContext>()
  const { t } = useTranslation(['campaigns'])

  const options = useMemo(
    () => [
      {
        value: 1,
        name: t('campaigns:campaign_priority.highest_value', { priority: 1 }),
      },
      {
        value: 2,
        name: t('campaigns:campaign_priority.high_value', { priority: 2 }),
      },
      {
        value: 3,
        name: t('campaigns:campaign_priority.normal_value', { priority: 3 }),
      },
      {
        value: 4,
        name: t('campaigns:campaign_priority.low_value', { priority: 4 }),
      },
      {
        value: 5,
        name: t('campaigns:campaign_priority.lowest_value', { priority: 5 }),
      },
    ],
    [t],
  )

  return (
    <SelectInput
      name="campaign.priority"
      control={control}
      options={options}
      label={t('campaigns:campaign_form.priority_label')}
      helperText={t('campaigns:campaign_form.priority_description')}
    />
  )
}
