import { useEffect } from 'react'
import {
  FieldPath,
  FieldValues,
  PathValue,
  UseFormReturn,
} from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

type Option = { value: string }

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = {
  name: TName
  formContext: UseFormReturn<TFieldValues>
  options: Option[]
  isLoading?: boolean
}

export const useSelectFirst = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: Props<TFieldValues, TName>,
) => {
  const { name, formContext, options, isLoading } = props

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (isLoading) return

    const firstOptionValue = options[0]?.value as PathValue<TFieldValues, TName>

    const value = formContext.getValues(name)
    const isOptionAvailable = options.some((option) => option.value === value)
    const isNotSelected = !value && !!firstOptionValue
    const isSynced = value === searchParams.get(name)

    if (isNotSelected || !isSynced || !isOptionAvailable) {
      formContext.setValue(name, firstOptionValue)
    }
  }, [formContext, isLoading, name, options, searchParams])
}
