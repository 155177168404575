import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { PrimitiveCircleSmallIcon } from '@sitoo/mui-components'
import { useMoney } from '../../../hooks/money'
import { Stack } from '@mui/material'

export const TotalRenderer = (
  params: GridRenderCellParams<
    { moneytotal_gross_all?: string | null },
    TotalProps
  >,
): React.ReactElement => <Total moneyTotal={params.row.moneytotal_gross_all} />

export type TotalProps = {
  moneyTotal?: string | null
}

const Total = (props: TotalProps): React.ReactElement => {
  const { formatCurrency } = useMoney()

  const moneyTotal = Number(props.moneyTotal) || 0

  return (
    <Stack direction="row" alignItems="center" justifyContent="right">
      {formatCurrency(props.moneyTotal || 0, {
        forceDecimals: true,
        hideCurrency: true,
      })}
      <PrimitiveCircleSmallIcon color={moneyTotal >= 0 ? 'success' : 'error'} />{' '}
    </Stack>
  )
}
