import { ReactiveVar, useQuery, useReactiveVar } from '@apollo/client'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { ServiceOrdersIcon } from '@sitoo/mui-components'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import { DataGridImageRenderer } from '../../../components/data-grid-image'
import { FilterContext } from '../../../components/data-grid/context'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { SortItem } from '../../../components/data-grid/filters/sort'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import {
  GetServiceOrdersSort,
  GetStoresDocument,
  ServiceOrderItem,
  ServiceOrderState,
  ServiceOrdersDocument,
  ServiceOrdersQuery,
  ServiceOrdersQueryVariables,
  Store,
} from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { FormatCurrencyOptions, useMoney } from '../../../hooks/money'
import { ArrayElement } from '../../../utils/types'
import { ServiceOrderStateChip } from '../service-order-state-chip'
import { ServiceOrderStateCategory } from './state-filter'
import { serviceOrderViewPanelVar } from '..'

export type Row = ArrayElement<ServiceOrdersQuery['serviceOrders']['items']>

type Props = {
  onShowFilter(): void
  onServiceOrderClick(serviceOrderId: string): void
}

export const ServiceOrdersList = (props: Props) => {
  const { formatRelativeDate, formatDate } = useLocalizedDate()
  const { formatCurrency } = useMoney()
  const { t } = useTranslation(['service_orders'])
  const apiRef = useGridApiRef()
  const PAGE_SIZE = 100

  const { filter, isFilterReady } = useContext(FilterContext)
  const { serviceOrderId } = useReactiveVar(serviceOrderViewPanelVar)
  const getServicesString = useCallback(
    (services: ServiceOrderItem[]) => {
      if (!services || services.length === 0) return ''
      if (services.length === 1) return services[0]?.name || ''
      return `${services[0]?.name || ''} ${t('plus_more', { amount: services.length - 1 })}`
    },
    [t],
  )

  const { data: storesData } = useQuery(GetStoresDocument)

  const sortItems = useMemo<SortItem<GetServiceOrdersSort>[]>(
    () => [
      {
        field: 'created',
        sort: 'asc',
        title: t('date_created'),
        type: 'text',
        value: GetServiceOrdersSort.CreatedAsc,
      },
      {
        field: 'created',
        sort: 'desc',
        title: t('date_created'),
        type: 'text',
        value: GetServiceOrdersSort.CreatedDesc,
        isDefault: true,
      },
    ],
    [t],
  )

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'id',
        minWidth: 120,
        headerName: t('id'),
        valueGetter: (_value, row): string => {
          return `#${row.id}`
        },
      },
      {
        field: 'created',
        minWidth: 100,
        headerName: t('date_created'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'store',
        minWidth: 200,
        headerName: t('store'),
        valueFormatter: (value: Store) => `${value.name} (${value.id})`,
      },
      {
        field: 'customer',
        minWidth: 160,
        headerName: t('customer'),
        valueGetter: (_value, row) =>
          [row.customer.first_name, row.customer.last_name]
            .filter(Boolean)
            .join(' '),
      },
      {
        field: 'productImages',
        ...ColumnProps.image,
        headerName: '',
        valueGetter: (_value, row) => row.product.images?.[0],
        cellClassName: 'image-column',
        headerClassName: 'image-column',
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'product',
        ...ColumnProps.productTitle,
        headerName: t('product'),
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.product.name,
          caption: getServicesString(row.product.services),
        }),
        renderCell: CaptionRenderer,
      },

      {
        field: 'state',
        minWidth: 100,
        headerName: t('state'),
        valueGetter: (_value, row): string => row.state || '',
        renderCell: (params) => {
          return (
            <ServiceOrderStateChip
              label={t(`states.${params.row.state}`)}
              state={params.row.state}
            />
          )
        },
      },

      {
        field: 'total',
        ...ColumnProps.price,
        headerName: t('total'),
        valueGetter: (_value, row): CaptionRendererValue => {
          const totalBeforeShare = row.product.totalBeforeShare
          const totalAfterShare = row.product.totalAfterShare
          const formatCurrencyOptions: FormatCurrencyOptions = {
            currency: row.currency,
            forceDecimals: true,
          }

          return {
            title: formatCurrency(totalAfterShare || 0, formatCurrencyOptions),
            caption:
              totalAfterShare !== totalBeforeShare &&
              t('original_price', {
                amount: formatCurrency(
                  totalBeforeShare || 0,
                  formatCurrencyOptions,
                ),
              }),
          }
        },
        renderCell: CaptionRenderer,
      },

      {
        field: 'note',
        minWidth: 200,
        headerName: t('note'),
      },
    ],
    [t, formatCurrency, formatDate, formatRelativeDate, getServicesString],
  )

  const columnVisibilityModel = useMemo(
    () => ({
      id: true,
      created: true,
      store: true,
      state: true,
      productImages: true,
      product: true,
      total: true,
      customer: true,
      note: true,
    }),
    [],
  )

  const sortModel = apiRef.current.state?.sorting.sortModel
  const queryVariables = useMemo(() => {
    const config = {
      pagination: {
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: ServiceOrdersQueryVariables = {
      num: config.pagination?.pageSize,
      start: 0,
    }

    if (filter['storeId']?.value) {
      const selectedStore = storesData?.stores.find(
        (store) => store.externalid === filter['storeId']?.value,
      )
      variables.store = selectedStore?.externalid
    }

    if (filter['state']?.value === ServiceOrderStateCategory.ACTIVE) {
      variables.state = [
        ServiceOrderState.InProgress,
        ServiceOrderState.New,
        ServiceOrderState.ServiceDone,
      ]
    }

    if (sortModel) {
      const sortItem = sortModel[0]
      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    return variables
  }, [apiRef, filter, sortItems, sortModel, storesData])

  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery(ServiceOrdersDocument, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: queryVariables,
    skip: !isFilterReady,
  })

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel
    if (data?.serviceOrders.next) {
      return fetchMore({
        variables: {
          next: data?.serviceOrders.next,
          start: (data?.serviceOrders?.start || 0) + pageSize,
        },
      })
    }
  }, [data?.serviceOrders.next, fetchMore, data?.serviceOrders.start, apiRef])

  return (
    <DataGrid
      name="service-orders-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={data?.serviceOrders.items}
      rowCount={data?.serviceOrders.items?.length || 0}
      loading={isLoading}
      fetchMore={fetchMoreItems}
      slots={{
        pagination: CursorPagination,
      }}
      sx={{
        '.filter-row-container': {
          gridTemplateColumns: 'auto auto auto 1fr auto',
        },
      }}
      disableColumnFilter
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      showMore={!!data?.serviceOrders.next}
      onShowFilter={props.onShowFilter}
      sorting={sortItems}
      onRowClick={(row) => props.onServiceOrderClick(String(row.id))}
      noRowsOverlay={{
        icon: <ServiceOrdersIcon />,
        title: t('grid.empty_title'),
        description: t('grid.empty_description'),
      }}
      hideTotalCount
      columnVisibilityModel={columnVisibilityModel}
      getRowClassName={(params) => {
        const classes = []
        if (params.id === serviceOrderId) {
          classes.push('Mui-selected')
        }

        if (
          [ServiceOrderState.Cancelled, ServiceOrderState.Closed].includes(
            params.row.state as ServiceOrderState,
          )
        ) {
          classes.push('disabled')
        }

        return classes.join(' ')
      }}
    />
  )
}
