import { useQuery } from '@apollo/client'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DashboardDataDocument } from '../../generated/graphql'
import { useMe } from '../../hooks/me'
import { getErrorMessages } from '../../utils/error-mapping'
import { useDashboardMessage } from './use-dashboard-message'
import { useSnackbar } from 'notistack'

type Props = {
  dashboardId: string | undefined
  storeId: string | undefined
}

export const Dashboard = (props: Props) => {
  const { dashboardId, storeId } = props
  const { t } = useTranslation('store_dashboards')
  const { me } = useMe()
  const { enqueueSnackbar } = useSnackbar()

  const timeZone = me?.currentSite.timezone || 'Europe/Stockholm'

  const queryVariables = useMemo(() => {
    return {
      dashboardId: String(dashboardId),
      timestamp: Math.floor(Date.now() / 1000),
      timeZone,
      storeId: String(storeId),
      userIdStaff: me?.user?.externalid ?? '',
    }
  }, [dashboardId, me?.user?.externalid, storeId, timeZone])

  const { loading, data, error } = useQuery(DashboardDataDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    skip: !me || !dashboardId || !storeId,
  })

  const dashboard = data?.dashboardData?.dashboard
  const templateUrl = data?.dashboardData?.templateUrl
  const config = data?.dashboardConfiguration

  const { iframeRef } = useDashboardMessage({ templateUrl, dashboard, config })

  const isLoading = loading

  const errorMessage = getErrorMessages(error).join(', ')

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }, [enqueueSnackbar, errorMessage])

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      {isLoading && <CircularProgress />}

      {errorMessage && (
        <Typography
          variant="body02"
          sx={{ maxWidth: '90%', textAlign: 'center' }}
        >
          {errorMessage}
        </Typography>
      )}

      {!isLoading && (
        <Box
          title={t('dashboard_title')}
          component="iframe"
          ref={iframeRef}
          src={templateUrl}
          sx={{
            overflowY: 'scroll',
            border: 'none',
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </Stack>
  )
}
