import { useQuery } from '@apollo/client'
import { useCallback } from 'react'
import {
  AllPaymentTypesDocument,
  OrderLookupFilterPaymentMethodEnum as PaymentMethod,
  PosPaymentMethod,
} from '../../../generated/graphql'

const paymentMethodMap = {
  [PosPaymentMethod.CASH]: PaymentMethod.Cash,
  [PosPaymentMethod.CREDIT_NOTE]: PaymentMethod.CreditNote,
  [PosPaymentMethod.CARD_VERIFONE_LPP]: PaymentMethod.VerifoneLpp,
  [PosPaymentMethod.CARD_HOST2T]: PaymentMethod.Host2t,
  [PosPaymentMethod.CARD_EXTERNAL]: PaymentMethod.ExternalCard,
  [PosPaymentMethod.GIFT_CARD]: PaymentMethod.GiftCard,
  [PosPaymentMethod.CREDIT_NOTE_AS_GIFT_CARD]: PaymentMethod.GiftCardCreditNote,
  [PosPaymentMethod.INVOICE]: PaymentMethod.Invoice,
  [PosPaymentMethod.CASH_ON_DELIVERY]: PaymentMethod.CashOnDelivery,
  [PosPaymentMethod.KLARNA]: PaymentMethod.KlarnaV3,
  [PosPaymentMethod.SWISH]: PaymentMethod.Swish,
  [PosPaymentMethod.SWISH_WITH_QR]: PaymentMethod.SwishQr,
  [PosPaymentMethod.MOBILEPAY]: PaymentMethod.MobilePay,
  [PosPaymentMethod.VIPPS]: PaymentMethod.Vipps,
  [PosPaymentMethod.VIPPS_MOBILEPAY]: PaymentMethod.VippsMobilePay,
  [PosPaymentMethod.FOREIGN_CURRENCY_CASH]: PaymentMethod.ForeignCurrency,
  [PosPaymentMethod.CUSTOM_METHOD_1]: PaymentMethod.Custom1,
  [PosPaymentMethod.CUSTOM_METHOD_2]: PaymentMethod.Custom2,
  [PosPaymentMethod.CUSTOM_METHOD_3]: PaymentMethod.Custom3,
  [PosPaymentMethod.CUSTOM_METHOD_4]: PaymentMethod.Custom4,
  [PosPaymentMethod.CUSTOM_METHOD_5]: PaymentMethod.Custom5,
  [PosPaymentMethod.CUSTOM_INVOICE]: PaymentMethod.CustomInvoice,
  [PosPaymentMethod.CUSTOM_PAYMENT_API]: PaymentMethod.CustomPayment,
  [PosPaymentMethod.CARD_ADYEN_SDK]: PaymentMethod.Adyen,
  [PosPaymentMethod.CARD_ADYEN_TERMINAL_API]: PaymentMethod.AdyenApi,
  [PosPaymentMethod.CARD_ADYEN_TAP_TO_PAY]: PaymentMethod.AdyenTapToPay,
  [PosPaymentMethod.SITOO_PAYMENTS]: PaymentMethod.AdyenApi,
  [PosPaymentMethod.CARD_NETS]: PaymentMethod.Nets,
  [PosPaymentMethod.CARD_COINEY]: PaymentMethod.Coiney,
  [PosPaymentMethod.CARD_IZETTLE]: PaymentMethod.IZettle,
  [PosPaymentMethod.CARD_VERIFONE_AIRPAY]: PaymentMethod.PointAirpay,
} as Record<string, PaymentMethod>

/**
 * Match PosPaymentMethods (platform api) with PaymentMethod (order lookup api)
 */
export const usePaymentMethod = () => {
  const { data, loading: isLoading } = useQuery(AllPaymentTypesDocument)

  const getPaymentMethods = useCallback(
    (paymentMethodIds: string[]): PaymentMethod[] => {
      if (!data?.allPaymentTypes) return []

      const paymentMethods = new Set<PaymentMethod>()

      paymentMethodIds.forEach((paymentTypeId) => {
        const paymentType = data.allPaymentTypes.find(
          (type) => String(type.paymenttypeid) === String(paymentTypeId),
        )

        const paymentMethodId = paymentType?.paymentmethodid

        if (!paymentMethodId) return

        const paymentMethod = paymentMethodMap[paymentMethodId]

        if (paymentMethod) {
          paymentMethods.add(paymentMethod)
        }
      })

      return Array.from(paymentMethods)
    },
    [data?.allPaymentTypes],
  )

  return { getPaymentMethods, isLoading }
}
