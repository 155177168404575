import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { DashboardDocument } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useDashboardMessage } from '../../store-dashboards/use-dashboard-message'

export const SettingsDashboardPage = () => {
  const { t } = useTranslation(['pos_settings'])

  const { id } = useParams<{ id: string }>()
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { data, loading } = useQuery(DashboardDocument, {
    variables: { id: String(id) },
    skip: !id,
  })

  const DASHBOARD_URL = import.meta.env.VITE_DASHBOARD_URL

  const { iframeRef } = useDashboardMessage({
    dashboard: data?.dashboard,
    templateUrl: DASHBOARD_URL,
    config: data?.dashboardConfiguration,
  })

  useEffect(() => {
    if (loading) return

    if (!data?.dashboard) {
      enqueueSnackbar(t('pos_settings:dashboards.not_found'), {
        variant: 'error',
      })
      navigate(generatePath(RootRoute.SettingsPosDashboards))
    }
  }, [data?.dashboard, enqueueSnackbar, generatePath, loading, navigate, t])

  // Fetch dashboard data, then render iframe
  if (loading) return null

  return (
    <Box
      ref={iframeRef}
      component="iframe"
      src={DASHBOARD_URL}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
        border: 'none',
      }}
    />
  )
}
